import styled, { css } from 'styled-components';

import { Layout, NextButton } from '../RegistrationForm/styles';
import { CustomCheckbox } from '../CreateEvent/styles';


const Wrapper = styled(Layout)`
`;

const Arrow = styled.span`
border-bottom: 10px solid transparent;
border-left: 12px solid #159fc9;
border-top: 10px solid transparent;
float: left;
cursor: pointer;
float: right;
  margin-top: ${({ isOtherSports }) => isOtherSports ? '5px' : '6px'};
  
  ${({active}) => active && css`
    border-left: 10px solid transparent;
    border-top: 12px solid #159fc9;
    border-right: 10px solid transparent;
  `}
`;

const InterestTile = styled.button`
  width: 100%;
  float: left;
  height: 60px;
  background: rgba(156, 156, 156, 0.1);
  padding: 0 25px 0 20px;
  margin-top: 10px;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  &:active, &:hover, &:focus {
    outline: none;
  }
  
  > svg {
    margin-right: 5px;
  }
  
  > img {
    float: left;
    margin-right: 10px;
  }

  div{
    > img {
    float: left;
    margin-right: 10px;
  }

  ${({ isIndividualSport }) => isIndividualSport && css`
  div{
    > img {
      width: 20px;
      margin-top: 5px;
    }

  }
    > img {
      width: 20px;
      margin-top: 5px;
    }
  `}
  @media (max-width: 600px) {
    padding-right:10px;
  }
`;

const TileTitle = styled.span`
  font-size: 18px;
  letter-spacing: 0px;
  color: #0D4270;
  font-family: Rubik-medium;
  text-transform: capitalize;
  float: left;
  line-height: ${({smallLine}) => smallLine ? '21px' : '30px'};
`;

const TilesDiv=styled.div`
display:flex;
padding:25px;
padding-right: 10px;

flex-wrap:wrap;
justify-content:flex-start;
border: 1px solid rgba(156, 156, 156, 0.4);
border-radius: 6px;
@media (max-width: 600px) {
  padding:10px 0;
  width: 100%;
  border:none;
  justify-content:center;
}
`
const SectionWrapper = styled.div`
  width: ${({Edit}) => Edit ? '100%' : '100%'};
  float: ${({Edit}) => Edit ? 'left' : 'right'};
  padding: ${({Edit}) => Edit ? '10px 0px' : '20px'};
  &:first-child {
    float: left;

  }
 
  
  @media (max-width: 600px) {
    width: 100%;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
    border-bottom:none

  }
`;

const SportTile = styled.div`
cursor:pointer;  
width: 31.96%;
  max-width:372px;
  float: left;
  height: 80px;
  border: none;
  border: 1px solid rgba(156, 156, 156, 0.4);
border-radius: 6px;
  padding: 0 0px 0 10px;
  background-color: white;
margin-bottom:15px;
margin-right:15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  >span {
    font-size: 16px;
    margin-left:15px;
    letter-spacing: 0px;
    color: #0D4270;
    text-transform: capitalize;
  }
  .right{
    display: flex;
    justify-content:center;
    align-items:center;
    
  }
  .right  >span {
    font-size: 16px;
    margin-left:15px;
    letter-spacing: 0px;
    color: #0D4270;
    text-transform: capitalize;
  }
  .right .imgDivSport{
    height:60px;
    width:60px;
    background: linear-gradient(180deg, #1E6481 0%, #0D425C 60.94%, #022C43 100%);
    border-radius: 6px;
    display: flex;
    justify-content:center;
    align-items:center;

  }
  .right .imgDivSport >img {
    width:70%;
    float: right;
  }
  @media (max-width: 1040px) {
    width: 29%;}
    @media (max-width: 800px) {
      width: 45%;}

  @media (max-width: 600px) {
    width: 100%;
  height:60px;
 
margin-right:0px;

  .right .imgDivSport{
    height:40px;
    width:40px;}
}
`;

const StyledCheckBox = styled(CustomCheckbox)`
  margin-top: ${({ top }) => top && top };
  > span {
    line-height: 23px;
    text-transform: capitalize;
    letter-spacing: 0px;
    color: rgb(153,153,153);
  }
`;

const ButtonContainer = styled.div`
 
width: 100%;
background:white;
display: flex;
justify-content:space-between;
align-items: center;
text-align: center;
// box-shadow: 13px -5px 14px rgba(0, 0, 0, 0.1);
border-top:1px solid rgba(156,156,156,0.6);
padding-top:25px;
@media (max-width: 600px) {
  width: calc(100% + 32px);
  margin: 0;
  margin-left: -16px;
  justify-content:center; 
  position:unset;
 flex-direction: column-reverse;
  flex-wrap:wrap;
  padding:25px 0;
  border-top:none;
  box-shadow:unset;
  background: #f3f6f9;


}
`;
const CheckMark2 = styled.span`
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  position: relative;
  height: 24px;
  width: 24px;
  padding:4px;
  border-radius: 50%;
  border: 1px solid #9c9c9c;
box-shadow:0px 0px 0px 3px white inset;
    background:${({ checked }) => checked ?"linear-gradient(360deg, #022C43 0%, #0D425C 60.94%, #1E6481 100%)":"unset"};
    display:flex;
justify-content:center;
align-items:center;
  &::after {
    content: "";
    position: absolute;
    display: block; 
    width: 5.5px;
    height: 9px;
    border-style: solid;
    border-width: 0 3px 3px 0;
    border-color: ${({ checked }) => checked ? 'white' : '#efefef'};
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top-color: transparent;
    border-left-color: transparent;
  }
`;

const Button = styled(NextButton)`
margin:0;



  width: 200px;
  background-color: ${({color,disabled}) => color ? color :disabled?"#9C9C9C": '#69c2ff'};
  border: ${({color,disabled}) => color ? `1px solid ${color}` :disabled?"#9C9C9C": '1px solid #69c2ff'};
  float: right;
  padding: 10px;
  font-size: 18px !important;
    color: ${({color}) => color &&  'rgba(105, 194, 255, 1)'};

  &:first-child {
  font-size: 18px !important;

    float: left;
  }
  
  > i {
    margin-right: 0;
    margin-left: 5px;
  }
  
  ${({color}) => color && css`
    > i {
      margin-left: 0;
      margin-right: 5px;
    }
  `}
  @media (max-width: 600px) {
    width: 90%;
    margin-top:5px;
   background-color:${({color}) => color && "transparent"}
   border:${({color}) => color &&"none"}
  }

`;

export { Wrapper, Arrow, InterestTile, CheckMark2, TilesDiv, SectionWrapper, SportTile, StyledCheckBox, Button, ButtonContainer, TileTitle };
