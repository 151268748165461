import styled from  'styled-components';
import { Modal } from 'react-bootstrap';

const BoldFont = 'Rubik-Medium';

const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 40%;
    .modal-content{
      border-radius: 7px 7px 6px 6px;
    }
    .modal-header {
      background: linear-gradient(180deg,#52A8ED 0%,#8AD3F7 100%);
      border-radius: 6px 6px 0px 0px;
    }
    .modal-body{
      padding: 5px 0 0 0;
    }
    @media (max-width: 1000px) {
      width: 60%;
    }
    
    @media (max-width: 767px) {
      width: 90%;
      margin: 30px auto;
    }
  }
`;

const CustomTitle = styled(Modal.Title)`
float: left;
width: 100%;

> div {
  float: left;
  width: 100%;
  display: flex;
  > div {
    font-size: 12px;
    color: #a9a9a9;
    display: flex;
  }

  > span {
    font-size: 18px;
    letter-spacing: 0px;
    font-family: ${BoldFont};
    color: white;
    width: 70%;
  }
  .closeImage{
    width: 30%;
    display: flex;
    justify-content: flex-end;
    >img{
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}

@media (max-width: 500px) {
  padding: 0 10px;
}
`;

const ContentWrapper = styled.div`
  width: 100%;
  padding: 20px 25px 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex !important;
    align-items: center;
    width: 70%;
    .image {
      width: 60px !important;
      height: 60px !important;
      padding: 0px;
      > img {
        width: auto;
        height: 60px !important;
        border-radius: 3px;
        float: left;
      }
    }
  
    .attendee{
      display: block;
      margin: auto;
      margin-left: 15px;
      >span{
        font-size: 16px;
        font-family: rubik-medium;
        color: #282C37;
        line-height: 24px;
      }
    >p{
      font-size: 14px;
      font-family: rubik;
      color: #9EA0A5;
      margin: 0px;
      line-height: 16px;
    }
  }
}


  @media (max-width: 400px) {
    width: 100%;
    display: block;
    margin: 0 auto;
    text-align: center;
    > div{
     width: 100%;
     justify-content: center;
    }
  }
`;

const NoGuests = styled.div`
  width: 100%;
  padding: 30px;
  font-size: 15px;
  font-family: ${BoldFont};
  text-align: center;
`;
const DeleteButton = styled.button`
    width: 110px;
    border-radius: 6px;
    background-color: #FFF;
    position: relative;
    font-size: 16px;
    color:#9c9c9c;
    font-family: rubik-medium;
    text-align: center;
    padding: 10px 0;
    outline: none;
    border: 2px solid rgb(216, 216, 216);
 `;
const RemoveAttendeesContainer = styled.div`
    max-height: calc(100vh - 260px);
    overflow-x: hidden;
    overflow-y: auto;
    overflow: -moz-scrollbars-none;
    margin: 25px;
    /* border: 1px solid #d8d8d8; */
    border-top: none;
    border-right: none;
    margin-bottom: 0px;
    margin-bottom: ${({margin}) => margin ? margin : '0px' };
   ::-webkit-scrollbar {
     width: 5px;
     height: 5px;
   }

    ::-webkit-scrollbar-thumb {
     background: #c7c8c7;
     cursor: pointer;
     border-radius: 2.5px;
   }

    ::-webkit-scrollbar-track {
     background: #fff;
     border-radius: 2.5px;
   }   
`;

const ArrowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content:center
  > div: first-child {
    width: ${({ width }) => width ? width : '0px'};
    display: flex;
    justify-content: flex-end;
    >img{
      width: auto;
      height: 30px;
      margin: auto;
      margin-right: 15px;
      margin-top: 15px;
      margin-bottom: 15px;
      cursor:pointer;
    }
  }
  >div:nth-child(2){
    margin: auto;
    margin-left: 0px;
    margin-right: 0px;
  }
  >div:nth-child(3){
    width: 50px;
    display: flex;
    justify-content: flex-start;
    >img{
      width: auto;
      height: 30px;
      margin: auto;
      margin-left: 15px;
      margin-top: 15px;
      margin-bottom: 15px;
      cursor:pointer;
    }
  }
`;

const PaginationCircle = styled.div`
  width: 27px;
  height: 27px;
  border-radius: 6px;
  background-color: ${({ active }) => active ? '#0D4270' : ''};
  display: inline-block;
  margin-left: 10px;
  font-size: 14px;
  letter-spacing: 0px;
  font-family: rubik;
  margin: auto;
  margin-left: 10px;
  margin-right: 0px;
  > div {
    color: ${({ active }) => active ? '#ffffff' : '#999999' };
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

export { StyledModal, CustomTitle, ContentWrapper, NoGuests, DeleteButton, RemoveAttendeesContainer, ArrowContainer, PaginationCircle};