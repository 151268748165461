/* eslint-disable no-undef*/
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { StyledModal, CustomTitle, ContentWrapper, DeleteButton, NoGuests, RemoveAttendeesContainer, ArrowContainer, PaginationCircle } from './styles';
import Image from '../Image';
const DeleteUserPopUp = React.lazy(() => import("../DeletePostPopup/deletePostPopup"));
import { withTranslation } from 'react-i18next';

class RemoveAttendeesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: '',
      userId: '',
      eventId: '',
      user: 20,
      minLimit: 1,
      maxLimit: 1
    }
  }

  showConfirmation = (name, AttendeeId, event) => {
    this.setState({ show: name, userId: AttendeeId, eventId: event });
  };
  onClose = () => {
    this.setState({ show: '' });
  };

  leftArrow = () => {
    const { user, minLimit, maxLimit } = this.state;
    if (user > 20 && minLimit >= 1) {
      this.setState({
        user: user - 20,
        minLimit: minLimit - 1,
        maxLimit: maxLimit - 1
      })
    }
  };

  rightArrow = (users) => {
    const { user, minLimit, maxLimit } = this.state;
    if (users.length > user) {
      this.setState({
        user: user + 20,
        maxLimit: maxLimit + 1
      })
    }
    if ((users.length / 20) > minLimit) {
      this.setState({
        minLimit: minLimit + 1
      })
    }
  };

  render() {
    const { Attendees, onHide, showModal, t, eventIdValue } = this.props;
    const { show, userId, eventId, user, maxLimit, minLimit } = this.state;
    let AttendeesShow = Attendees.slice(0, user);
    let usersCount = Math.ceil(Attendees.length / 20);
    return (
      <StyledModal show={showModal} onHide={() => onHide()}>
        <Modal.Header style={{background:"#005c87"}}>
          <CustomTitle>
            <div>
              <span>{t("Edit Attendees")}</span>
              <span className="closeImage"><img src='/public/images/new_close_icon.png' onClick={() => onHide()} /></span>
            </div>
          </CustomTitle>
        </Modal.Header>
        <Modal.Body>
          <RemoveAttendeesContainer margin={Attendees.length > 20 ? '0px' : '25px'}>
            {
              AttendeesShow.length > 0 ?
                AttendeesShow.map((attendee, index) => (
                  <ContentWrapper key={index} style={{border:'1px solid #669db7',borderRadius:"6px"}}>
                    <div>
                      <div className="image">
                        <Image image={attendee.profile_image} alt={attendee.fullname} />
                      </div>
                      <div className="attendee">
                        <span style={{color:"#005c87"}}>{`${attendee.fname} ${attendee.lname}`}</span>
                        <p>{t(attendee.department)}</p>
                      </div>
                    </div>
                    <DeleteButton style={{background:"#005c87",color:"white",border:'1px solid #669db7'}} onClick={() => { this.showConfirmation('removeAttendees', attendee.uid, eventIdValue) }}>{t("Remove")}</DeleteButton>
                  </ContentWrapper>

                )) :
                <NoGuests style={{color:"#005c87",border:"1px solid #6399af",padding:"20px"}}>{t("No registered guests")}</NoGuests>
            }

          </RemoveAttendeesContainer>
          {
            Attendees.length > 20 &&
            <ArrowContainer width={user > 20 ? '50px' : '0px'}>
              <div>{user > 20 ? <img src='/public/images/CompanyDashBoardV2/NewIcon/leftcrossIcon.png' onClick={() => this.leftArrow()} /> : ""}
              </div>
              {<div>
                {maxLimit == usersCount ? <PaginationCircle active={maxLimit >= usersCount ? 0 : 1}>
                  <div>{minLimit - 1}
                  </div>
                </PaginationCircle> :
                  <PaginationCircle active={maxLimit >= usersCount ? 0 : 1}>
                    <div>{minLimit}
                    </div>
                  </PaginationCircle>}
                <PaginationCircle active={maxLimit >= usersCount ? 1 : 0}>
                  <div>{usersCount}
                  </div>
                </PaginationCircle>
              </div>}
              {maxLimit == usersCount ? '' : <div><img src='/public/images/CompanyDashBoardV2/NewIcon/rightcrossIcon.png' onClick={() => this.rightArrow(Attendees)} />
              </div>}
            </ArrowContainer> 
          }
        </Modal.Body>
        {show == 'removeAttendees' && <DeleteUserPopUp
          title="Edit Attendees"
          showModal={show}
          uid={userId}
          eventId={eventId}
          onClose={this.onClose}
        />
        }
      </StyledModal>

    )
  }
}

RemoveAttendeesModal.propTypes = {
  Attendees: PropTypes.array.isRequired,
  showModal: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  t: PropTypes.func,
  eventIdValue: PropTypes.func
};

export default (withTranslation()(RemoveAttendeesModal));
